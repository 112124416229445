import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import Menu from "@material-ui/core/Menu";

import Logout from './Logout';
import styles from './ProfileDropDown.module.scss';

const SettingsLink: React.FC = () => (
  <div >
    <FontAwesomeIcon icon={faCog} />
    <span>Settings</span>
  </div>
);

type propTypes = {
  setProfileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userName?: string;
};

const ProfileDropDown = ({ setProfileOpen, userName }: propTypes) => {
  const divEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    divEl?.current?.focus();
  });

  return (
    <div
      className={styles.MoreMenuBtn}
      ref={divEl}
      tabIndex={-1}
      onBlur={() => setProfileOpen(false)}
    >
      <div>
        <span>{userName}</span>
      </div>
      <SettingsLink />
      <Logout />
    </div>

  );
};

export default ProfileDropDown;
