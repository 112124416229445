import React from 'react';

import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';
import SelectInput from '../../components/EditableInputs/SelectInput';
import TextAreaInput from '../../components/EditableInputs/TextAreaInput';
import TextInput from '../../components/EditableInputs/TextInput';
import style from '../../neumorpism.module.scss'

import {
  QUOTE_ACTIONS,
  QuoteActionStateTypeMap,
  QuoteActions,
  QuoteType,
} from './quotationReducer';

const QuotationValues = ({
  dispatch,
  editing,
  employees,
  quote,
  specialities,
  categories,
  quoteState,
}: {
  dispatch: React.Dispatch<QuoteActions>;
  editing: boolean;
  employees?: Many2OneType[];
  quote?: QuoteType;
  specialities?: Many2OneType[];
  categories?: Many2OneType[];
  quoteState: boolean;
}) => (
  <>
  <div className={style.FormWrapper}>
    
    <div className={style.FormHeader}>
      <div className={style.Heading}>
        <h2>Quotation Values</h2>
      </div>
    </div>
    
    <div className="ValueGrid">
      <TextInput
        editing={quoteState}
        name="Name *"
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_NAME, value })
        }
        value={quote?.name}
      />
      <Many2OneInput
        editing={quoteState}
        link=""
        name="Speciality *"
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_SPECIALITY, value })
        }
        value={quote?.speciality}
        values={specialities}
      />
      <TextInput
        editing={quoteState}
        name="Practice Number *"
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_PRACTICE_NUMBER, value })
        }
        value={quote?.practice_number}
      />
      <TextInput
        editing={quoteState}
        name="Email Address *"
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_EMAIL, value })
        }
        value={quote?.email}
      />
      <SelectInput
        editing={quoteState}
        name="Quote State"
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_ACTION_STATE, value })
        }
        options={QuoteActionStateTypeMap}
        value={quote?.action_state}
      />
      {quote?.action_state === 'cancelled' ?
        <TextAreaInput
          name="Reason"
          value={quote?.cancel_reason}
          editing={editing}
          onChange={(value) =>
            dispatch({ type: QUOTE_ACTIONS.QUOTE_CANCEL_REASON, value })
          }
        />
      : null
      }
      <TextAreaInput
        name="Notes"
        value={quote?.note_section}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_NOTE_SECTION, value })
        }
      />
      <Many2OneInput
        editing={quoteState}
        name="Sale Employee *"
        link={`/employee/${quote?.sale_employee?.id}`}
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_SALE_EMPLOYEE, value })
        }
        value={quote?.sale_employee}
        values={employees}
      />
    
    </div>
    </div>
  </>
);

export default QuotationValues;
