import 'react-toastify/dist/ReactToastify.css';

import ReactPDF from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import BusinessDetailsDebitOrderPDF from '../BusinessDetails/BusinessDetailsDebitOrderPDF';

import { fetchQuoteApi, getTermsAndConditions } from './quotationAPI';
import { QuoteType } from './quotationReducer';
import { get, post } from '../../utils/api';
import MyDocument, { QuoteDetailPropTypes, QuoteConfigPropTypes, QuoteConfigBankingDetails } from './QuoteTemplate';
import BusinessDetails from '../BusinessDetails/BusinessDetails';
import { fetchBusinessDetails, fetchBankingDetails } from '../BusinessDetails/BusinessDetailsAPI';
import AmendQuotation from './AmendQuotation';
import DeclineQuotation from './DeclineQuotation';
import { BusinessDetailsType } from '../BusinessDetails/BusinessDetailsReducer';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import LoadingSpinner from '../../components/Messages/LoadingSpinner';
import CustomPDFViewer from '../../utils/CustomPDFViewer';
import { MergePDF } from '../../utils/MergePDF';
import styles from './AcceptQuotation.module.scss';
import responsive from '../../responsive.module.scss';


const AcceptQuoteDetails: React.FC = () => {
  const { id, jwt_token }: any = useParams();
  const [quote, setQuote] = useState({} as QuoteType);
  const [quoteLines, setQuoteLines] = useState({} as QuoteDetailPropTypes[]);
  const [isQuoteLoaded, setQuoteLoaded] = useState(false);
  const [isQuoteLinesLoaded, setQuoteLinesLoaded] = useState(false);
  const [isBusinessDisabled, setBusinessDetails] = useState(false);
  const [isAmendQuoteDisabled, setAmendedQuote] = useState(false);
  const [isDeclineQuoteDisabled, setDeclineQuote] = useState(false);
  const [isBankingDetailsLoaded, setBankingDetailsLoaded] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState({"isValid": ""});
  const [quoteConfig, setQuoteConfig] = useState({} as QuoteConfigPropTypes);
  const [businessValues, setBusinessValues] = useState({} as BusinessDetailsType);
  const [bankingDetails, setBankingDetails] = useState({} as QuoteConfigBankingDetails[]);
  const fileName = `GoodX_Quotation_${quote?.id}`;
  const [pdfBlob, setPdfBlob] = useState('');
  const [TermsAndConditionsErr, setTermsAndConditionsErr] = useState<string | null>(null);

  const InvalidQuotation = (
    <React.Fragment>
      <h3 style={{ textAlign: 'center', color: 'red' }}><b>Invalid Quotation ID entered.</b></h3>
      <span style={{ textAlign: 'center' }}>Please reclick the link on the email received.</span>
      <span style={{ textAlign: 'center' }}>If the issue still persists, please contact support on <b>012 804 6831</b>.</span>
    </React.Fragment>
  );

  function isIOSAndSafari() {
    const platform = window.navigator.platform;
    const userAgent = window.navigator.userAgent;
  
    return platform === 'iPad' && userAgent.includes('Safari') && !userAgent.includes('Chrome') || platform === 'MacIntel' && userAgent.includes('Safari') && !userAgent.includes('Chrome');
  }
  
  const isIOSWithSafari = isIOSAndSafari();

  const showApproveForm = () => {
    setQuoteLoaded(false);
    setAmendedQuote(false);
    setDeclineQuote(false);
    setBusinessDetails(true);
   };

  const showQuoteForm = () => {
    setBusinessDetails(false);
    setAmendedQuote(false);
    setDeclineQuote(false);
    setQuoteLoaded(true);
  };

  const declineQuoteForm = () => {
    setQuoteLoaded(false);
    setAmendedQuote(false);
    setBusinessDetails(false);
    setDeclineQuote(true);
  };

  const amendQuoteForm = () => {
    setQuoteLoaded(false);
    setBusinessDetails(false);
    setDeclineQuote(false);
    setAmendedQuote(true);
  };

  const savePDFContract = async () => {
    const signedPdfRef = pdfBlob;
    fetch(signedPdfRef)
    .then(response => response.blob())
    .then(blob => {
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          const blobdata = reader.result;
          const base64string = blobdata as string
          const base64s = base64string?.split(',')[1];
          post(`/quotation/quote_pdf_insert`, { base64s, id });
        };
      reader.readAsDataURL(blob);
      })
    })
    .catch(error => {
      console.error("Unable to save PDF Contract:", error);
    });
  };

  const downloadPDFContract = async () => {
    const pdfContent = pdfBlob; 
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfContent;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfContent);
  };

  const getPdfBlobData = async () => {
    try {
      if (isQuoteLinesLoaded && isQuoteLoaded && isBankingDetailsLoaded) {
        const firstBlob = await ReactPDF.pdf(<MyDocument props={quote} item_lines={quoteLines} quote_config={quoteConfig} business_details={businessValues} banking_details={bankingDetails} />).toBlob();
        const secondBlob = await getTermsAndConditions(setTermsAndConditionsErr);
        const mergedPDF = await MergePDF(firstBlob, secondBlob);
        const dataUrl = URL.createObjectURL(mergedPDF);
        return dataUrl
      }
    } catch (error) {
      return null
    }
  };

  const getMergePdfData = async() => {
    try {
      const dataUrl = await getPdfBlobData();
      if (dataUrl !== undefined && dataUrl && !pdfBlob) {
        setPdfBlob(dataUrl);
        return dataUrl;
      } else {
        return null
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      const validateToken = async () => {
        const resp = await post(`/quotation/validate_token`, {jwt_token, id});

        if (resp.ok) {
          setIsTokenValid(await resp.json());
        }
      }
      validateToken();
    }
  },[id, jwt_token]);

  useEffect(() => {
    if (id !== undefined) {
      const fetchQuoteConfig = async () => {
        const resp = await get('/quotation/config');

        if (resp.ok) {
          setQuoteConfig(await resp.json());
        }
      };

      fetchQuoteConfig();
    }
  }, [id, setQuoteConfig]);

  useEffect(() => {
    fetchQuoteApi(`/quotation/${id}`, setQuote, setQuoteLoaded);
    fetchQuoteApi(
      `/quotation/${id}/previewlines`,
      setQuoteLines,
      setQuoteLinesLoaded
    );
   fetchBusinessDetails(`/quotation/business_details/${id}`, setBusinessValues);
  }, [id]);

  useEffect(() => {
    if (id !==undefined) {
      fetchBankingDetails(`/quotation/config/banking_details`, setBankingDetails, setBankingDetailsLoaded);
    }
  },[id, setBankingDetails]);

  useEffect(() => {
    const onPageLoadDebitOrder = async () => {
      const pdfRef = ReactPDF.pdf(<BusinessDetailsDebitOrderPDF props={businessValues} quoteConfig={quoteConfig} bankingDetails={bankingDetails}  />).toBlob();
      if(pdfRef){
        const pdfs = await pdfRef;
        const url = URL.createObjectURL(pdfs);

        new Promise((resolve) => {
          var reader = new FileReader();
          reader.readAsDataURL(pdfs); 
          reader.onloadend = function() {
            var base64data:any = reader.result;
            const base64 = base64data?.split(',')[1];
            post(`/quotation/debit_order_pdf`, {base64, id});
          }
      })
      };
    };

    if (businessValues.debit_order_bool) {
      setTimeout(onPageLoadDebitOrder, 2000);
    }
  }, [quoteConfig, businessValues, bankingDetails]);

  if (isQuoteLinesLoaded && isQuoteLoaded && isBankingDetailsLoaded) {
    setTimeout(savePDFContract, 2000);
    getMergePdfData();
  };
 
  return (
    <>
    {isTokenValid.isValid ?
    <React.Fragment>
      <div className={styles.ButtonPanel}>
        <DesktopLayout>
        {quote?.action_state !== "accepted" ?
          <button className={styles.SubmitBtn} onClick={showQuoteForm}>
            <span className="material-icons">visibility</span> View Quote
          </button>
        :
          <button className="ViewQuoteBtn" onClick={showQuoteForm}>
            <span className="material-icons">visibility</span> View Signed Quote
          </button>
        }
        </DesktopLayout>
        <DesktopLayout>
        {quote?.action_state !== "accepted" && quote?.id !== undefined ?
          <button className={styles.SubmitBtn} onClick={showApproveForm} onChange={() => setQuoteLoaded(false)}>
            <span className="material-icons">done</span> Accept Quote
          </button>
        : null
        }
        </DesktopLayout>
        <MobileLayout>
        {!businessValues.quote_id && quote?.id !== undefined ?
          <button className={responsive.SubButton} onClick={showApproveForm} onChange={() => setQuoteLoaded(false)}>
            <span className="material-icons">done</span>
          </button>
        : null
        }
        </MobileLayout>

        {quote?.id !== undefined ?
        <React.Fragment>
          <DesktopLayout>
          <button className={styles.SubmitBtn} onClick={amendQuoteForm}>
            <span className="material-icons">edit</span> Amend Quote
          </button>

          <button className={styles.SubmitBtn} onClick={declineQuoteForm}>
            <span className="material-icons">close</span> Decline Quote
          </button>
          </DesktopLayout>

          <MobileLayout>
          <button className={responsive.SubButton} onClick={amendQuoteForm}>
            <span className="material-icons">edit</span>
          </button>

          <button className={responsive.SubButton} onClick={declineQuoteForm}>
            <span className="material-icons">close</span>
          </button>
          </MobileLayout>
        </React.Fragment>
        : null
        }
      </div>
     
      {isQuoteLoaded && isQuoteLinesLoaded && isBankingDetailsLoaded ?
        quote?.id !== undefined ?
          <React.Fragment>
            <MobileLayout>
              {pdfBlob ? (
                <div style={{textAlign: 'center'}}>
                  <br />
                  <button style={{
                    justifyItems: 'center', height: '36px', backgroundColor: '#0081c6', 
                    color: 'white', borderRadius: '5px', textTransform: 'uppercase',
                    fontFamily: 'Roboto Condensed',
                    fontWeight: 700,
                    boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
                    transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
                    letterSpacing: '1px',
                    fontSize: '16px',
                    }} 
                    className="AcceptQuoteBtn"
                    onClick={() => downloadPDFContract()}
                    >
                    Download PDF Quotation
                  </button>
                </div>
                ) : (
                  !TermsAndConditionsErr ? 
                    <LoadingSpinner text={"Loading PDF contract..."} /> 
                  : 
                    <LoadingSpinner text={"Error loading PDF contract, please try again."} /> 
              )}
            </MobileLayout>
            <DesktopLayout>
            {isIOSWithSafari && pdfBlob ? (
              <div style={{textAlign: 'center'}}>
                <br />
                <button style={{
                  justifyItems: 'center', height: '36px', backgroundColor: '#0081c6', 
                  color: 'white', borderRadius: '5px', textTransform: 'uppercase',
                  fontFamily: 'Roboto Condensed',
                  fontWeight: 700,
                  boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
                  transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
                  letterSpacing: '1px',
                  fontSize: '16px',
                  }} 
                  className="AcceptQuoteBtn"
                  onClick={() => downloadPDFContract()}
                  >
                  Download PDF Quotation
                </button>
              </div>
            ): (
              <div className="PDFView">
                {pdfBlob ? (
                    <CustomPDFViewer fileUrl={pdfBlob} />
                ) : (
                  !TermsAndConditionsErr ? 
                    <LoadingSpinner text={"Loading PDF contract..."}  /> 
                  : 
                    <LoadingSpinner text={"Error loading PDF contract, please try again..."} style={{ color: 'red'}} button={true} /> 
                )}
              </div>
            )} 
            </DesktopLayout>
          </React.Fragment>
        : InvalidQuotation
      : null}
      {isBusinessDisabled ?
        <BusinessDetails />
      : null}
      {isAmendQuoteDisabled ?
        <AmendQuotation />
      : null}
      {isDeclineQuoteDisabled ?
        <DeclineQuotation />
      : null}
      <ToastContainer />
    </React.Fragment>
    : InvalidQuotation }
    </>
  );
};

export default AcceptQuoteDetails;
