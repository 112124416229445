import React from 'react';
import { format as timeago } from 'timeago.js';
import styles from '../Messages/Messages.module.scss';

const TimeAgo = ({ date }: { date: Date }) => {
  return (
      <span title={date.toLocaleString()}>{date ? timeago(date) : 'Never'}</span>
  );
};

export default TimeAgo;
