import ReactPDF from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBankingDetails, fetchBusinessDetails } from '../BusinessDetails/BusinessDetailsAPI';

import { BusinessDetailsType } from '../BusinessDetails/BusinessDetailsReducer';
import { fetchQuoteApi, getTermsAndConditions } from './quotationAPI';
import { QuoteType } from './quotationReducer';
import MyDocument, { QuoteConfigBankingDetails, QuoteConfigPropTypes, QuoteDetailPropTypes } from './QuoteTemplate';
import { MergePDF } from '../../utils/MergePDF';
import LoadingSpinner from '../../components/Messages/LoadingSpinner';
import CustomPDFViewer from '../../utils/CustomPDFViewer';

const QuoteDownloadLink: React.FC = () => {
    const { id }: any = useParams();
    const [quote, setQuote] = useState({} as QuoteType);
    const [quoteLine, setQuoteLines] = useState([] as QuoteDetailPropTypes[]);
    const [isQuoteLoaded, setQuoteLoaded] = useState(false);
    const [isQuoteLinesLoaded, setQuoteLinesLoaded] = useState(false);
    const [isBankingDetailsLoaded, setBankingDetailsLoaded] = useState(false);
    const [isConfigLoaded, setConfigLoaded] = useState(false);
    const [quoteConfig, setQuoteConfig] = useState({} as QuoteConfigPropTypes);
    const [businessValues, setBusinessValues] = useState({} as BusinessDetailsType);
    const [bankingDetails, setBankingDetails] = useState({} as QuoteConfigBankingDetails[]);
    const [TermsAndConditionsErr, setTermsAndConditionsErr] = useState<string | null>(null);
    const [pdfBlob, setPdfBlob] = useState('');

    const getPdfBlobData = async () => {
      try {
        if (isQuoteLinesLoaded && isQuoteLoaded && isBankingDetailsLoaded) {
          const firstBlob = await ReactPDF.pdf(<MyDocument props={quote} item_lines={quoteLine} quote_config={quoteConfig} business_details={businessValues} banking_details={bankingDetails} />).toBlob();
          const secondBlob = await getTermsAndConditions(setTermsAndConditionsErr);
          const mergedPDF = await MergePDF(firstBlob, secondBlob);
          const dataUrl = URL.createObjectURL(mergedPDF);
          return dataUrl
        }
      } catch (error) {
          return null
      }
    };
    
    const getMergePdfData = async() => {
      try {
        const dataUrl = await getPdfBlobData();
        if (dataUrl !== undefined && dataUrl && !pdfBlob) {
          setPdfBlob(dataUrl);
          return dataUrl;
        } else {
          return null
        }
      } catch (error) {
        return null;
      }
    };

    useEffect(() => {
        if (id !== undefined) {
            fetchQuoteApi(`/quotation/${id}`, setQuote, setQuoteLoaded);
            fetchQuoteApi(
                `/quotation/${id}/previewlines`,
                setQuoteLines,
                setQuoteLinesLoaded
            );
            fetchQuoteApi(`/quotation/config`, setQuoteConfig, setConfigLoaded);
            fetchBusinessDetails(`/quotation/business_details/${id}`, setBusinessValues);
            fetchBankingDetails(`/quotation/config/banking_details`, setBankingDetails, setBankingDetailsLoaded);
        }
    }, [id]);

    if (isQuoteLinesLoaded && isQuoteLoaded && isBankingDetailsLoaded && isConfigLoaded) {
      getMergePdfData();
    };

    return (
        <>
        <div className="PDFView">
          {pdfBlob ? (
              <CustomPDFViewer fileUrl={pdfBlob} />
          ) : (
            !TermsAndConditionsErr ? 
              <LoadingSpinner text={"Loading PDF contract..."}  /> 
            : 
              <LoadingSpinner text={"Error loading PDF contract, please try again..."} style={{ color: 'red'}} button={true} /> 
          )}
        </div>
        </>
    );
};

export default QuoteDownloadLink;