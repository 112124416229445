import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import TextInputValidation from '../../components/EditableInputs/TextInputValidation';
import { ToastContainer } from 'react-toastify';
import { DeclineQuoteSave } from './quotationAPI';
import styles from '../BusinessDetails/BusinessDetails.module.scss';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

const DeclineQuotation: React.FC = () => {
  const { id }:any = useParams();
  const [businessName, setBusinessName] = useState('');
  const [practiceNr, setPracticeNr] = useState('');
  const [reason, setReason] = useState('');
  const [businessNameErr, setBusinessNameErr] = useState('');
  const [practiceNrErr, setPracticeNrErr] = useState('');
  const [errors, setError] = useState('');

  const validateFields = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (businessName === '') {
      setBusinessNameErr('Please enter your business name');
    } else {
      setBusinessNameErr('');
    }
    if (practiceNr.length < 7) {
        setPracticeNrErr('Practice number must be 7 characters long');
    } else {
        setPracticeNrErr('');
    }
  };

  const DeclineQuoteSubmit = async() => {
    setError('');
    if (
        businessName &&
        practiceNr.length > 6 &&
        reason
    ) {
      DeclineQuoteSave(
        id,
        businessName,
        practiceNr,
        reason,
    );
    }
  };

  return (
    <>
    <DesktopLayout>
    <div className={styles.FormWrapper}>
      <form onSubmit={validateFields}>

        <h2>Decline Quotation</h2>
        <br />

        <TextInputValidation
          editing={true}
          name="Name of Business/Practice: *"
          onChange={(event) => setBusinessName(event)}
          errors={businessNameErr}
        />

        <TextInputValidation
          editing={true}
          name="Practice (PCNS) number: *"
          onChange={(event) => setPracticeNr(event)}
          errors={practiceNrErr}
        />

        <label className={styles.top}>Reason for declining?</label>
        <textarea onChange={(event) => setReason(event.target.value)}> </textarea>
        <div></div>
        <div></div>

        <button
          className={styles.SaveBtn}
          onClick={DeclineQuoteSubmit} >
          Decline Quote <span className="material-icons">arrow_forward</span>
        </button>

        <ToastContainer />
        <span className={styles.ErrorMsg}>{errors}</span>

      </form>
    </div>
    </DesktopLayout>
    <MobileLayout>
    <div className={responsive.PageWrapper}>
      <form onSubmit={validateFields}>
      <div className={responsive.OverviewPanel}>
        <div className={styles.Heading}>
          <h2>Decline Quotation</h2>
        </div>
      </div>
        <br />
        <TextInputValidation
          editing={true}
          name="Name of Business/Practice: *"
          onChange={(event) => setBusinessName(event)}
          errors={businessNameErr}
        />

        <TextInputValidation
          editing={true}
          name="Practice (PCNS) number: *"
          onChange={(event) => setPracticeNr(event)}
          errors={practiceNrErr}
        />

        <label className={styles.top}>Reason for declining?</label>
        <textarea onChange={(event) => setReason(event.target.value)}> </textarea>
        <div></div>
        <div></div>

        <button
          className={responsive.SubmitButton}
          onClick={DeclineQuoteSubmit} >
          Decline Quote <span className="material-icons">arrow_forward</span>
        </button>

        <ToastContainer />
        <span className={responsive.ErrorMsg}>{errors}</span>

      </form>
    </div>
    </MobileLayout>
    </>
  )
};

export default DeclineQuotation;
