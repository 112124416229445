import { History } from 'history';
import { toast } from 'react-toastify';

import { get, post, put } from '../../utils/api';
import toISODateString from '../../utils/toISODateString';
import {
  QUOTE_ACTIONS,
  QuoteActions,
  QuoteBillablePrice,
  QuoteLineType,
  QuoteType,
} from './quotationReducer';

export const fetchQuote = async (
  dispatch: React.Dispatch<QuoteActions>,
  id?: string
) => {
  if (id === undefined) {
    return undefined;
  }

  const resp = await get(`/quotation/${id}`);
  if (resp.ok) {
    const quote = await resp.json();
    dispatch({
      type: QUOTE_ACTIONS.QUOTE,
      value: {
        ...quote,
        accepted_date:
          quote.accepted_date === null
            ? undefined
            : new Date(quote.accepted_date),
        bill_from_date:
          quote.bill_from_date === null
            ? undefined
            : new Date(quote.bill_from_date),
        install_date:
          quote.install_date === null
            ? undefined
            : new Date(quote.install_date),
      },
    });
    fetchQuoteLines(dispatch, id);
  }
};

const fetchQuoteLines = async (
  dispatch: React.Dispatch<QuoteActions>,
  id?: string
) => {
  if (id === undefined) {
    return undefined;
  }

  const resp = await get(`/quotation/${id}/lines`);
  if (resp.ok) {
    dispatch({ type: QUOTE_ACTIONS.QUOTE_LINES, value: await resp.json() });
  }
};

export const fetchPriceListPrices = async (
  dispatch: React.Dispatch<QuoteActions>,
  priceListID?: number
) => {
  if (priceListID === undefined) {
    return undefined;
  }

  const resp = await get(`/quotation/price_list/${priceListID}/prices`);
  if (resp.ok) {
    const lines: QuoteBillablePrice[] = await resp.json();
    dispatch({
      type: QUOTE_ACTIONS.QUOTE_LINES,
      value: lines.map(
        (line, index) =>
          ({
            id: -index,
            sale_price: line.expected_price,
            quantity: 1.0,
            billable_price: line,
            show_on_invoice: true,
          } as QuoteLineType)
      ),
    });
  }
};

export const entitySetupSendEmail = async (quote?: QuoteType) => {
  if (quote === undefined) {
    return undefined;
  }

  const resp = await post('/install_setup_send_email', {
    quote_id: quote.id,
    email: quote.email,
  });

  alert(await resp.clone().text());
};

const quoteToJSON = (quote: QuoteType) => ({
  accepted_date: toISODateString(quote.accepted_date) || null,
  action_state: quote.action_state,
  bill_from_date: toISODateString(quote.bill_from_date) || null,
  category_id: quote.category?.id,
  email: quote.email,
  install_date: toISODateString(quote.install_date) || null,
  name: quote.name,
  practice_number: quote.practice_number,
  price_list_id: quote.price_list?.id,
  sale_employee_id: quote.sale_employee.id,
  speciality_id: quote.speciality?.id,
  cancel_reason: quote.cancel_reason,
  note_section: quote.note_section,
});

const quoteLinesJSON = (quote: QuoteType) => ({
  lines: quote.lines?.map((line) => ({
    id: line.id,
    sale_price: line.sale_price,
    quantity: line.quantity,
    billable_price_id: line.billable_price.id,
    billable_item_name: line.billable_price.billable_item.name,
    show_on_invoice: line.show_on_invoice,
  })),
});

export const saveNewQuote = async (
  quote: QuoteType | undefined,
  history: History
) => {
  if (!quote) {
    return undefined;
  }

  const respQuote = await put('/quotation', quoteToJSON(quote));

  if (respQuote.ok) {
    const { new_id } = await respQuote.json();
    const respLines = await post(
      `/quotation/${new_id}/lines`,
      quoteLinesJSON(quote)
    );

    if (respLines.ok) {
      history.push({ pathname: `/quotation/${new_id}` });
    }
  }
};

export const saveQuote = async (quote?: QuoteType, id?: string) => {
  if (!quote || !id) {
    return undefined;
  }

  const respQuote = await post(`/quotation/${id}`, quoteToJSON(quote));

  if (respQuote.ok) {
    const respLines = await post(
      `/quotation/${id}/lines`,
      quoteLinesJSON(quote)
    );

    if (respLines.ok) {
      window.location.reload();
    }
  }
};

export const fetchQuoteApi = async (
  url: string,
  setFn: (arg0: any) => void,
  loading: (arg0: any) => void
) => {
  const resp = await get(url);
  if (resp.ok) {
    setFn(await resp.json());
    loading(true);
  }
  return resp.ok;
};


export const AmendQuoteSave = async (
  quote_id: string,
  businessName: string,
  contactPerson: string,
  cellNr: string,
  practiceNr: string
) => {
  const resp = await post('/quotation/quote_amend', {
    quote_id: quote_id,
    businessName: businessName,
    contactPerson: contactPerson,
    cellNr: cellNr,
    practiceNr: practiceNr,
  });
  if (resp.ok) {
    toast.success('Your response has been succesfully sent.');
    } else {
      toast.error('Unable to submit response due to error. ' + await resp.text());
    }
  };

export const DeclineQuoteSave = async (
  quote_id: string,
  businessName: string,
  practiceNr: string,
  reason: string
) => {
  const resp = await post('/quotation/quote_decline', {
    quote_id: quote_id,
    businessName: businessName,
    practiceNr: practiceNr,
    reason: reason,
  });
  if (resp.ok) {
    toast.success('Your response has been succesfully sent.');
    } else {
      toast.error('Unable to submit response due to error. ' + await resp.text());
    }
  };


export const quoteDuplicateCheck = async (
  practice_no?: string
) => {
  const resp = await post('/quotation/duplicate_check', {
    practice_no: practice_no,
  });

  if (resp.ok) {
    const data = await resp.json();
    if (data) {
      return data;
    }
    } 
  };

export const quoteDuplicateCreate = async (
  quote_id?: string
) => {
  const resp = await post('/quotation/duplicate_create', {
    quote_id: quote_id,
  });

  if (resp.ok) {
    const data = await resp.json();
    if (data) {
      return data;
    }
    } 
  };

export const getTermsAndConditions = async (
  setFn: React.Dispatch<string | null>
) => {
  try {
    const resp = await get('/quotation/terms_and_conditions');

    if (resp.ok) {
      const data = await resp.arrayBuffer();
      const blob = new Blob([data], { type: 'application/pdf' });
      return blob;
    }
    else {
      setFn('Unable to load PDF contract');
      return null;
    }
  } catch (error) {
      setFn('An error occurred while fetching terms and conditions.');
      return null; 
  }
};