import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './employee_contracts.module.scss';
import { EmergencyContactDetail } from './EmployeeContractDetail';
import { useParams } from 'react-router-dom';
import { post } from '../../utils/api';

interface IFourthChildProps {
    updateEmergencyContact: (arg: any) => void;
    currentContract: EmergencyContactDetail;
}


const EmployeeEmergencyContact: React.FC<IFourthChildProps> = ({ updateEmergencyContact, currentContract }) => {
    const { jwt_token }: any = useParams();
    const [contactName, setContactName] = useState('');
    const [contactCellNo, setContactCellNo] = useState('');
    const [relationship, setRelationship] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [emergencyContactComplete, setEmergencyContactComplete] = useState(true)
    const [errorContactName, setErrorContactName] = useState('');
    const [errorCellNo, setErrorCellNo] = useState('');
    const [errorRelationship, setErrorRelationship] = useState('');
    const [error, setError] = useState(false);
    const [emergencyContactDetail, setEmergencyContactDetail] = useState({
        'contactName': contactName,
        'contactCellNo': contactCellNo,
        'relationship': relationship,
        'emergencyContactComplete':emergencyContactComplete,
    } as EmergencyContactDetail);

    const handleChange = (e: any) => {
        setError(false);
        const { name, value } = e.target;
        setEmergencyContactDetail(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    useEffect(() => {
        updateEmergencyContact(emergencyContactDetail);
    },);

    const loadCurrentContract = () => {
        if (currentContract) {
            setEmergencyContactDetail(currentContract)
        }
    }

    useEffect(() => {
        setTimeout(function () {
            if (loaded === false) {
                loadCurrentContract();
                setLoaded(true);
            }
        }, 1000);
    },);

    const formValidate = async (e: { preventDefault: () => void }) => {
        setError(false);
        if (emergencyContactDetail.contactName === ''|| emergencyContactDetail.contactName === null) {
            setErrorContactName('Please enter your emergency contact name');
            setError(true);
        } else {
            setErrorContactName('');
        }
        if (emergencyContactDetail.contactCellNo === '' || emergencyContactDetail.contactCellNo === null) {
            setErrorCellNo('Please enter your emergency contact cell no');
            setError(true);
        } else {
            setErrorCellNo('');
        }
        if (emergencyContactDetail.relationship === '' || emergencyContactDetail.relationship === null) {
            setErrorRelationship('Please enter your relationship with your emergency contact ');
            setError(true);
        } else {
            setErrorRelationship('');
        }
        if (error === true) {
            e.preventDefault();
        }
      };

    const save_emergency_contact_detail = async (e: any) => {
        if (emergencyContactDetail.contactName === '' || emergencyContactDetail.contactName === null) {
            setError(true);
        }else if (emergencyContactDetail.contactCellNo === ''|| emergencyContactDetail.contactCellNo === null) {
            setError(true);
        }else if (emergencyContactDetail.relationship === ''|| emergencyContactDetail.relationship === null) {
            setError(true);
        }else if(error === false) {
            setEmergencyContactComplete(true);
            const resp = await post(`/save_employee/save_emergency_contact_detail/${jwt_token}`, {
                'emergencyContactDetail':emergencyContactDetail,
                'emergencyContactComplete':emergencyContactComplete
            });
            if (resp.ok) {
                return resp.ok;
            }
        };
    };

    return (
        <form onSubmit={formValidate}>
            <div className={styles.FormWrapper}>
                <div className={styles.form}>
                    <div>
                        <label>Emergency Contact Name:</label>
                        <input
                            className={styles.input}
                            type="text"
                            name="contactName"
                            value={emergencyContactDetail.contactName}
                            onChange={(handleChange)}
                        />
                        <span className={styles.errormsg}>{errorContactName}</span>
                    </div>
                    <br />
                    <div>
                        <label>Emergency Contact Cellphone Number:</label>
                        <input
                            className={styles.input}
                            type="text"
                            name="contactCellNo"
                            value={emergencyContactDetail.contactCellNo}
                            onChange={(handleChange)}
                        />
                        <span className={styles.errormsg}>{errorCellNo}</span>
                    </div>
                    <br />
                    <div>
                        <label>Relationship:</label>
                        <input
                            className={styles.input}
                            type="text"
                            name="relationship"
                            value={emergencyContactDetail.relationship}
                            onChange={(handleChange)}
                        />
                        <span className={styles.errormsg}>{errorRelationship}</span>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.btn_footer}>
                        <button className={styles.save_button} onClick={save_emergency_contact_detail}>Complete</button>
                    </div>
                </div>
            </div>
        </form>
    )

}

export default EmployeeEmergencyContact; 
