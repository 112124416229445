import { faAngleDoubleRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OTPInput from '../../components/EditableInputs/OTPInput';
import { post, get } from '../../utils/api';
import styles from '../EmployeeContracts/employee_contracts.module.scss';

export type cellNumber= {
  cell_number: string;
};

const OTPFormValidateEmployee: React.FC = () => {
  const { jwt_token }: any = useParams();
  const history = useHistory();
  const [signedOtp, setSignedOtp] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState('');
  const [signedCellNo, setSignedCellNo] = useState({} as cellNumber);
  const [errors, setError] = useState('');

  const fetchFromDb = async (url:any, setFn:any) => {
    const resp = await get(url);
    if (resp.ok) {
      setFn(await resp.json());
    }
    return resp.ok;
};

  useEffect(() => {
    fetchFromDb(`/employee_contract/get_number_otp/${jwt_token}`, setSignedCellNo);
    sendOTP()
},[]);

  const otpValidate = async (
    signedOtp?: string,
  ) => {
    const resp = await post(`/employee_contract/validate_otp_contract/${jwt_token}`, {
      signedOtp: signedOtp,
    });

    if (!resp.ok) {
        setError('Something went wrong. Please send OTP again.');
      return false;
    }

    const json = await resp.json();
    if (!json.exists) {
        setError('The OTP you entered is invalid');
    }
    else {
        setError('');
    }

    return json.exists;
  };

  const sendOTP = async () => {
    setDisabled(false);
    if (signedCellNo.cell_number === '') {
      setError("Please enter a cellphone number.")
    }
    else {
      setError("Please check your cellphone for your one time pin. If you did not recive it please contact legal@goodx.co.za")
    }
    const resp = await post(`/employee_contract/set_otp/${jwt_token}`, {
      signedCellNo: signedCellNo.cell_number,
    });

    if (resp.ok) {
    } else {
      setMessage('Something went wrong. Try to request a OTP again.');
    }
    return resp.ok;
  };

  const submitOTP = async(e: any) => {
    setError('');
    e.preventDefault();
    let otpValid = await otpValidate(signedOtp);
    console.log(otpValid);
    if (otpValid) {
      history.push({ pathname: `/employee/contracts/${jwt_token}` })
    }
  };

  return (
    <>
    <div className={styles.NavBar}>
        <h2>Employee Information Form</h2>
      </div>
    <form className={styles.firstOtpForm}>
      <div>
          <br />
          <h3 className={styles.info_msg}>Note!</h3> <h3>With this OTP, I declare that, to the best of my knowledge, the information provided is correct and complete and that my birthday may be shared on the company telephone list.</h3>
          <label>Cell No: {signedCellNo.cell_number}</label>
          <br />
          <OTPInput state={[signedOtp, setSignedOtp]} setMessage={setMessage} disabled={disabled} className={styles.otp}/>
          <br />
          <span className={styles.errormsg}>{message}</span>
          <br />
          <br />
          <button className={styles.btn_submit_otp} onClick={submitOTP}>
              <FontAwesomeIcon icon={faCheck} color="white" />
              Submit
          </button>
          <button className={styles.resend_otp} onClick={sendOTP}>
              <FontAwesomeIcon icon={faAngleDoubleRight} color="white" />
              Resend OTP
          </button>
          <br />
          <span className={styles.info_msg}>{errors}</span>
      </div>
    </form>
    </>
  )
};

export default OTPFormValidateEmployee;
