import React, { useEffect, useState } from 'react';

import styles from './Inputs.module.scss';

type CheckBoxInputPropTypes = {
  name: string;
  checked?: boolean;
  editing: boolean;
  onChange: (value: boolean) => void;
};

const CheckBoxInput = ({
  name,
  checked,
  editing,
  onChange,
}: CheckBoxInputPropTypes) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!editing) {
      setTouched(false);
    }
  }, [editing]);

  const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    onChange(e.target.checked);
  };

  return (
    <>
      <span>{name}</span>
      <input
        className={styles.FieldInputBox}
        type="checkbox"
        checked={checked || false}
        disabled={!editing}
        onChange={changed}
      />
    </>
  );
};

export default CheckBoxInput;
