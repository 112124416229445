import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { post } from '../../utils/api';
import { QuoteType } from '../Quotation/quotationReducer';

import TextInputValidation from '../../components/EditableInputs/TextInputValidation';
import styles from './BusinessDetails.module.scss';
import { BusinessDetailsType, fileLT2MBValidator } from './BusinessDetailsReducer';
import { fetchBusinessDetails, fetchSpecialities } from './BusinessDetailsAPI';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';
import InputUpload from '../../components/EditableInputs/InputUpload';
import Select from 'react-select';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

const BusinessDetails: React.FC = () => {
  const { id }:any = useParams();
  const [businessValues, setBusinessValues] = useState({} as BusinessDetailsType);
  const [quote, setQuote] = useState({} as QuoteType);
  const [tradingAs, setTradingAs] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [idRegNr, setIdRegNr] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [disciplineApi, setDisciplineApi] = useState([]);
  const [discipline, setDiscipline] = useState('');
  const [postalAddress, setPostalAddress] = useState('');
  const [postalAddress2, setPostalAddress2] = useState('');
  const [postalAddress3, setPostalAddress3] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [streetAddress3, setStreetAddress3] = useState('');
  const [streetCode, setStreetCode] = useState('');
  const [telNr, setTelNr] = useState('');
  const [cellNr, setCellNr] = useState('');
  const [faxNr, setFaxNr] = useState('');
  const [emailDebitOrder, setEmailDebitOrder] = useState('');
  const [emailAccounts, setEmailAccounts] = useState('');
  const [emailNewsletter, setEmailsNewsletter] = useState('');
  const [emailPracticeManager, setEmailPracticeManager] = useState('');
  const [creditControlName, setCreditControlName] = useState('');
  const [creditControlEmail, setCreditControlEmail] = useState('');
  const [IOCellNo, setIOCellNo] = useState('');
  const [practiceNr, setPracticeNr] = useState('');
  const [hpcsaNr, setHpcsaNr] = useState('');
  const [mhgUsername, setMHGUsername] = useState('');
  const [mhgPassword, setMHGPassword] = useState('');
  const [vatNr, setVatNr] = useState('');
  const [businessNameErr, setBusinessNameErr] = useState('');
  const [tradingAsErr, setTradingAsErr] = useState('');
  const [idRegNrErr, setIdRegNrErr] = useState('');
  const [contactPersonErr, setContactPersonErr] = useState('');
  const [disciplineErr, setDisciplineErr] = useState('');
  const [postalAddressErr, setPostalAddressErr] = useState('');
  // const [postalAddress2Err, setPostalAddress2Err] = useState('');
  // const [postalAddress3Err, setPostalAddress3Err] = useState('');
  // const [postalCodeErr, setPostalCodeErr] = useState('');
  const [streetAddressErr, setStreetAddressErr] = useState('');
  // const [streetAddress2Err, setStreetAddress2Err] = useState('');
  // const [streetAddress3Err, setStreetAddress3Err] = useState('');
  // const [streetCodeErr, setStreetCodeErr] = useState('');
  const [telNrErr, setTelNrErr] = useState('');
  const [cellNrErr, setCellNrErr] = useState('');
  // const [faxNrErr, setFaxNrErr] = useState('');
  const [emailDebitOrderErr, setEmailDebitOrderErr] = useState('');
  const [emailAccountsErr, setEmailAccountsErr] = useState('');
  const [emailNewsletterErr, setEmailsNewsletterErr] = useState('');
  const [creditControlNameErr, setCreditControlNameErr] = useState('');
  const [creditControlEmailErr, setCreditControlEmailErr] = useState('');
  const [practiceNrErr, setPracticeNrErr] = useState('');
  const [hpcsaNrErr, setHpcsaNrErr] = useState('');
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [acceptContact, setAcceptContact] = useState('');
  const [acceptContactErr, setAcceptContactErr] = useState('');
  const [seenSocialMediaErr, setSeenSocialMediaErr] = useState('');
  // const [mhgUsernameErr, setMHGUsernameErr] = useState('');
  // const [mhgPasswordErr, setMHGPasswordErr] = useState('');
  const [vatNrErr, setVatNrErr] = useState('');
  const [emailPracticeManagerErr, setEmailPracticeManagerErr] = useState('');
  const [IOCellNoErr, setIOCellNoErr] = useState('');
  const [idDocument, setIdDocument] = useState('');
  const [idDocumentErr, setIdDocumentErr] = useState('');
  const [companyDocument, setCompanyDocument] = useState('');
  const [companyDocumentErr, setCompanyDocumentErr] = useState('');
  const [bankDetails, setBankDetails] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [heardFrom, setHeardFrom] = useState({});
  const [seenSocialMedia, setSeenSocialMedia] = useState('');
  const [debitorderBool, setDebitOrderBool] = useState(false);
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [accountType, setAccountType] = useState('');
  const [dateOfDebitOrder, setDateOfDebitOrder] = useState('');
  const [debitorderBoolError, setDebitOrderBoolError] = useState('');
  const [bankError, setBankError] = useState('');
  const [bankBranchError, setBankBranchError] = useState('');
  const [branchCodeError, setBranchCodeError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [accountNoError, setAccountNoError] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');
  const [dateOfDebitOrderError, setDateOfDebitOrderError] = useState('');
  let regexEmail = /^[\w\.-]+@[\w\.-]+\.\w+$/;
  
  const HeardFromOptions = [
    { value: '1', label: 'Google' },
    { value: '2', label: 'Social Media Platform' },
    { value: '3', label: 'Publication - Magazine/Advert' },
    { value: '4', label: 'Marketing Emails' },
    { value: '5', label: 'Word of Mounth - Friend/Colleague' },
    { value: '6', label: 'Sales person - Cold Calling' },
    { value: '7', label: 'Events -Expos/Congress/Golf gathering' },
    { value: '8', label: 'Other' },
  ];

  const validateFields = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (businessName === '') {
      setBusinessNameErr('Please enter your business name');
    } else {
      setBusinessNameErr('');
    }
    if (tradingAs === '') {
      setTradingAsErr('Please enter your trading as name')
    }
    else {
      setTradingAsErr('');
    }
    if (idRegNr === '') {
      setIdRegNrErr('Please enter your ID/Registration number');
    } else {
      setIdRegNrErr('');
    }
    if (contactPerson === '') {
      setContactPersonErr('Please enter a contact person');
    } else {
      setContactPersonErr('');
    }
    if (discipline) {
      setDisciplineErr('Please select a discipline from the list');
    } else {
      setDisciplineErr('');
    }
    if (postalAddress === '') {
      setPostalAddressErr('Please enter a postal address');
    } else {
      setPostalAddressErr('');
    }
    // if (postalCode === '') {
    //   setPostalCodeErr('Please enter a postal code');
    // } else {
    //   setPostalCodeErr('');
    // }
    if (streetAddress === '') {
      setStreetAddressErr('Please enter a street address');
    } else {
      setStreetAddressErr('');
    }
    if (telNr.length < 10) {
      setTelNrErr('Telephone number must be 10 digits long');
    } else {
      setTelNrErr('');
    }
    if (cellNr.length < 10 ) {
      setCellNrErr('Cellphone number must be 10 digits long');
    } else {
      setCellNrErr('');
    }
    if (emailDebitOrder.match(regexEmail)) {
      setEmailDebitOrderErr('');
    } else {
      setEmailDebitOrderErr('Invalid debit order email address');
    }
    if (emailAccounts.match(regexEmail)) {
      setEmailAccountsErr('');
    } else {
      setEmailAccountsErr('Invalid accounts email address');
    }
    if (emailNewsletter.match(regexEmail)) {
      setEmailsNewsletterErr('');
    } else {
      setEmailsNewsletterErr('Invalid newsletter email address');
    }
    if (emailPracticeManager.match(regexEmail)) {
      setEmailPracticeManagerErr('');
    } else {
      setEmailPracticeManagerErr('Invalid practice manager email address');
    }
    if (creditControlName === '') {
      setCreditControlNameErr('Please enter the information officer\'s name');
    } else {
      setCreditControlNameErr('');
    }
    if (creditControlEmail.match(regexEmail)) {
      setCreditControlEmailErr('');
    } else {
      setCreditControlEmailErr('Invalid information officer email address');
    }
    if (IOCellNo.length < 10 ) {
      setIOCellNoErr('Information Officer cellphone number must be 10 digits long');
    } else {
      setIOCellNoErr('');
    }
    if (practiceNr.length < 7) {
      setPracticeNrErr('Practice number must be 7 characters long');
    } else {
      setPracticeNrErr('');
    }
    if (hpcsaNr === '') {
      setHpcsaNrErr('Please enter a your practice HPCSA number');
    } else {
      setHpcsaNrErr('');
    }
    if (acceptContact === '') {
      setAcceptContactErr('Please enter your full name');
    } else {
      setAcceptContactErr('');
    }
    if (seenSocialMedia === '') {
      setSeenSocialMediaErr('No option has been selected');
    } else {
      setSeenSocialMediaErr('');
    }
    if(debitorderBool===false && bankDetails==='') {
      setDebitOrderBoolError('Please attach proof of banking detail');
    } else {
      setDebitOrderBoolError('');
    }
    if (bank === '' && debitorderBool===false) {
      setBankError('Please Enter A Bank');
    } else {
        setBankError('');
    }
    if (branch === '' && debitorderBool===false) {
        setBankBranchError('Please Enter A Branch');
    } else {
        setBankBranchError('');
    }
    if (branchCode === '' && debitorderBool===false) {
        setBranchCodeError('Please Enter A Branch Code');
    } else {
        setBranchCodeError('');
    }
    if (accountName === '' && debitorderBool===false) {
        setAccountNameError('Please Enter A Account Name');
    } else {
        setAccountNameError('');
    }
    if (accountNo === '' && debitorderBool===false) {
        setAccountNoError('Please Enter A Account No');
    } else {
        setAccountNoError('');
    }
    if (accountType === '' && debitorderBool===false) {
        setAccountTypeError('Please Enter A Account Type');
    } else {
        setAccountTypeError('');
    }
    if (dateOfDebitOrder === '' && debitorderBool===false) {
        setDateOfDebitOrderError('Please Select Date Of Debit Order');
    } else {
        setDateOfDebitOrderError('');
    }
    if (idDocument === '' || idDocument === undefined) {
      setIdDocumentErr('Please attach ID document');
    }
    else {
      setIdDocumentErr('');
    }
    if (companyDocument === '' || companyDocument === undefined) {
      setCompanyDocumentErr('Please attach company document');
    }
    else {
      setCompanyDocumentErr('');
    }
  };

  const BusinessDetailsUpdate = async (field: any, value: any) => {
    const resp = await post(`/quotation/business_details_update/${quote.id}`, {
      field,
      value
    });

    if (resp.ok) {
      return true;
    } else {
      toast.error(resp.statusText + '. Contact GoodX for further assistance.');
    }
  };

  const BusinessDetailsSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: string,
    cellNr: string,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: string,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch:string,
    branchCode: string,
    accountName:string,
    accountNo:string,
    accountType:string,
    dateOfDebitOrder:string,
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/quote_approve', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool:debitorderBool,
      bank:bank,
      branch:branch,
      branchCode:branchCode,
      accountName:accountName,
      accountNo:accountNo,
      accountType:accountType,
      dateOfDebitOrder:dateOfDebitOrder
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      window.location.reload();
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const businessSubmit = async() => {
    if (agreementCheck) {
      if (
        businessName &&
        tradingAs &&
        businessType &&
        idRegNr &&
        contactPerson &&
        discipline &&
        postalAddress &&
        // postalAddress2 &&
        // postalAddress3 &&
        // postalCode &&
        streetAddress &&
        // streetAddress2 &&
        // streetAddress3 &&
        // streetCode &&
        telNr.length > 9 &&
        cellNr.length > 9 &&
        // faxNr &&
        emailDebitOrder.match(regexEmail) && emailDebitOrder !== '' &&
        emailAccounts.match(regexEmail) && emailAccounts !== '' &&
        emailNewsletter.match(regexEmail) && emailNewsletter !== '' &&
        emailPracticeManager.match(regexEmail) && emailPracticeManager !== '' &&
        creditControlName &&
        creditControlEmail.match(regexEmail) &&
        IOCellNo.length > 9 &&
        practiceNr.length > 6 &&
        hpcsaNr &&
        // mhgUsername &&
        // mhgPassword &&
        // vatNr &&
        acceptContact &&
        agreementCheck && 
        heardFrom &&
        seenSocialMedia 
      ) {
        BusinessDetailsSave(
          id,
          businessName,
          tradingAs,
          idRegNr,
          contactPerson,
          discipline,
          postalAddress,
          postalAddress2,
          postalAddress3,
          postalCode,
          streetAddress,
          streetAddress2,
          streetAddress3,
          streetCode,
          telNr,
          cellNr,
          faxNr,
          emailDebitOrder,
          emailAccounts,
          emailNewsletter,
          creditControlName,
          creditControlEmail,
          practiceNr,
          hpcsaNr,
          mhgUsername,
          mhgPassword,
          vatNr,
          acceptContact,
          agreementCheck,
          idDocument,
          companyDocument,
          bankDetails,
          IOCellNo,
          emailPracticeManager,
          businessType,
          heardFrom as any,
          seenSocialMedia,
          !debitorderBool,
          bank,
          branch,
          branchCode,
          accountName,
          accountNo,
          accountType,
          dateOfDebitOrder
        );
      }
    }
  };

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validation = (event:any,type:string , name:string) => {
    let err = ''
    switch(type) {
      case 'email':
        if (!isValidEmail(event.target.value)) {
          err ='Email is invalid';
        } else {
          err ='';
        }
    }
    switch(name) {
      case 'debitOrderEmail':
        return setEmailDebitOrderErr(err);
      case 'businessName':
        if (businessName === '') {
          return setBusinessNameErr('Please enter your business name');
        } else {
          return setBusinessNameErr('');
        }
      case 'tradingAs':
        if (tradingAs === '') {
          return setTradingAsErr('Please enter your trading as name')
        }
        else {
          return setTradingAsErr('');
        }
      case 'idRegNr':
        if (idRegNr === '') {
          return setIdRegNrErr('Please enter your ID/Registration number');
        } else {
          return setIdRegNrErr('');
        }
      case 'contactPerson':
        if (contactPerson === '') {
          return setContactPersonErr('Please enter a contact person');
        } else {
          return setContactPersonErr('');
        }
      case 'discipline':
        if (discipline) {
          return setDisciplineErr('Please select a discipline from the list');
        } else {
          return setDisciplineErr('');
        }
      case 'postalAddress':
        if (postalAddress === '') {
          return setPostalAddressErr('Please enter a postal address');
        }  
        if (postalAddress === '*') {
          setPostalAddress(streetAddress);
          setPostalAddress2(streetAddress2);
          setPostalAddress3(streetAddress3);
          setPostalAddressErr('');
        }
        else {
          return setPostalAddressErr('');
        }
      case 'streetAddress':
        if (streetAddress === '') {
          return setStreetAddressErr('Please enter a street address');
        } else {
          return setStreetAddressErr('');
        }
      case 'telNr':
        if (telNr.length < 10) {
          return setTelNrErr('Telephone number must be 10 digits long');
        } else {
          return setTelNrErr('');
        }
      case 'cellNr':
        if (cellNr.length < 10 ) {
          return setCellNrErr('Cellphone number must be 10 digits long');
        } else {
          return setCellNrErr('');
        }
      case 'emailAccounts':
        return setEmailAccountsErr(err);
      case 'emailNewsletter':
        return setEmailsNewsletterErr(err)
      case 'emailPracticeManager':
        return setEmailPracticeManagerErr(err)
      case 'creditControlName':
        if (creditControlName === '') {
          return setCreditControlNameErr('Please enter the information officer\'s name');
        } else {
          return setCreditControlNameErr('');
        }
      case 'creditControlEmail':
        if (creditControlEmail.match(regexEmail)) {
          return setCreditControlEmailErr('');
        } else {
          return setCreditControlEmailErr('Invalid information officer email address');
        }
      case 'IOCellNo':
        if (IOCellNo.length < 10 ) {
          return setIOCellNoErr('Information Officer cellphone number must be 10 digits long');
        } else {
          return setIOCellNoErr('');
        }
      case 'practiceNr':
        if (practiceNr.length < 7) {
          return setPracticeNrErr('Practice number must be 7 characters long');
        } else {
          return setPracticeNrErr('');
        }
      case 'hpcsaNr':
        if (hpcsaNr === '') {
          return setHpcsaNrErr('Please enter a your practice HPCSA number');
        } else {
          return setHpcsaNrErr('');
        }
      case 'acceptContact':
        if (acceptContact === '') {
          return setAcceptContactErr('Please enter your full name');
        } else {
          return setAcceptContactErr('');
        }
      case 'seenSocialMedia':
        if (seenSocialMedia === '') {
          return setSeenSocialMediaErr('No option has been selected');
        } else {
          return setSeenSocialMediaErr('');
        }
      case 'debitOrderBool':
        if(debitorderBool===false && bankDetails==='') {
          return setDebitOrderBoolError('Please attach proof of banking detail');
        } else {
          return setDebitOrderBoolError('');
        }
      case 'bank':
        if (bank === '' && debitorderBool===false) {
          return setBankError('Please Enter A Bank');
        } else {
          return setBankError('');
        }
      case 'branch':
        if (branch === '' && debitorderBool===false) {
          return setBankBranchError('Please Enter A Branch');
        } else {
          return setBankBranchError('');
        }
      case 'branchCode':
        if (branchCode === '' && debitorderBool===false) {
          return setBranchCodeError('Please Enter A Branch Code');
        } else {
          return setBranchCodeError('');
        }
      case 'accountName':
        if (accountName === '' && debitorderBool===false) {
          return setAccountNameError('Please Enter A Account Name');
        } else {
          return setAccountNameError('');
        }
      case 'accountNo':
        if (accountNo === '' && debitorderBool===false) {
          return setAccountNoError('Please Enter A Account No');
        } else {
          return setAccountNoError('');
        }
      case 'accountType':
        if (accountType === '' && debitorderBool===false) {
          return setAccountTypeError('Please Enter A Account Type');
        } else {
          return setAccountTypeError('');
        }
      case 'dateOfDebitOrder':
        if (dateOfDebitOrder === '' && debitorderBool===false) {
          return setDateOfDebitOrderError('Please Select Date Of Debit Order');
        } else {
          return setDateOfDebitOrderError('');
        }
    }
  };

  useEffect(() => {
   fetchSpecialities('/quotation/speciality', setDisciplineApi);
   fetchBusinessDetails(`/quotation/business_details/${id}`, setBusinessValues);
   fetchBusinessDetails(`/quotation/${id}`, setQuote);
  }, []);
  
  useEffect(() => {
    setTradingAs(businessValues.trading_as || '');
    setBusinessName(businessValues.business_name || '');
    setBusinessType(businessValues.business_type || '');
    setIdRegNr(businessValues.id_reg_nr || '');
    setContactPerson(businessValues.contact_person || '');
    setDiscipline(businessValues.discipline_id || '');
    setPostalAddress(businessValues.postal_address || '');
    setPostalAddress2(businessValues.postal_address2 || '');
    setPostalAddress3(businessValues.postal_address3 || '');
    setStreetAddress(businessValues.street_address || '');
    setStreetAddress2(businessValues.street_address2 || '');
    setStreetAddress3(businessValues.street_address3 || '');
    setTelNr(businessValues.tel_nr || '');
    setCellNr(businessValues.cell_nr || '');
    setFaxNr(businessValues.fax_nr || '');
    setEmailAccounts(businessValues.email_accounts || '');
    setEmailDebitOrder(businessValues.email_debit_order || '');
    setEmailsNewsletter(businessValues.email_newsletter || '');
    setEmailPracticeManager(businessValues.email_practice_manager || '');
    setCreditControlName(businessValues.credit_control_name || '');
    setCreditControlEmail(businessValues.credit_control_email || '');
    setIOCellNo(businessValues.io_cell_nr || ''); 
    setPracticeNr(businessValues.practice_nr || '');
    setHpcsaNr(businessValues.hpcsa_nr || '');
    setMHGUsername(businessValues.mhg_username || '');
    setMHGPassword(businessValues.mhg_password || '');
    setVatNr(businessValues.vat_number || '');
    setAcceptContact(businessValues.accept_contact || '');
    setBank(businessValues.debit_order_bank || '');
    setBranch(businessValues.debit_order_branch || '');
    setBranchCode(businessValues.debit_order_branch_code || '');
    setAccountName(businessValues.debit_order_account_name || '');
    setAccountNo(businessValues.debit_order_account_no || '');
    setAccountType(businessValues.debit_order_account_type || '');
    setDateOfDebitOrder(businessValues.date_of_debit_order || '');   
  }, [businessValues]);

  return (
    <>
    {quote?.action_state !== "accepted" ?
    <>
    <DesktopLayout>
    <div className={styles.FormWrapper}>
      <div className={styles.notification}>
        <p><strong>Info!</strong> Complete the form below to automatically save changes for later use, before submitting the form ...</p>
      </div>
      <form onSubmit={validateFields}>
      <div className={styles.FormHeader}>
        <div className={styles.Heading}>
          <h2>Quote: Part B</h2>
        </div>
      </div>
        
        
        <br />
        <div className={styles.FieldGroupSingle}>

        <TextInputValidation
          editing={true}
          name="Name of Business/Practice: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','businessName')
              BusinessDetailsUpdate('business_name', businessName)
            }
          }
          onChange={(event) => setBusinessName(event)} 
          errors={businessNameErr}
          value={businessName}
        />
        

        <TextInputValidation
          editing={true}
          name="Trading As: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','tradingAs')
              BusinessDetailsUpdate('trading_as', tradingAs)
            }
          }
          onChange={(event) => setTradingAs(event)}
          errors={tradingAsErr}
          value={tradingAs}
        />
        

        <label className={styles.top}>Business Type: *</label>
        <RadioGroup className={styles.column} row aria-label="position" value={businessType} onChange={(e) => setBusinessType(e.target.value)}>
          <FormControlLabel value="Sole Practitioner" control={<Radio />} label="Sole Practitioner" />
          <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
          <FormControlLabel value="Incorporation" control={<Radio />} label="Incorporation" />
          <FormControlLabel value="Close Corporation" control={<Radio />} label="Close Corporation" />
          <FormControlLabel value="(Pty) Ltd" control={<Radio />} label="(Pty) Ltd" />
          <FormControlLabel value="Trust" control={<Radio />} label="Trust" />
        </RadioGroup>

        <TextInputValidation
          editing={true}
          name="ID or Company Registration number: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','idRegNr')
              BusinessDetailsUpdate('id_reg_nr', idRegNr)
            }
          }
          onChange={(event) => setIdRegNr(event)}
          errors={idRegNrErr}
          value={idRegNr}
        />

        <TextInputValidation
          editing={true}
          name="Name of Contact Person: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','contactPerson')
              BusinessDetailsUpdate('contact_person', contactPerson)
            }
          }
          onChange={(event) => setContactPerson(event)}
          errors={contactPersonErr}
          value={contactPerson}
        />

        <label>Discipline: *</label>
        <select
          className={disciplineErr ? 'Error' : ''}
          value={discipline}
          onBlur={
            (e:any) => {
              validation(e, 'none','discipline')
              BusinessDetailsUpdate('discipline', discipline)
            }
          }
          onChange={(e) => setDiscipline(e.target.value)} >
          <option></option>
          {disciplineApi.map((item: any) => (
            <option key={item.name} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <div></div>
        <div></div>

        <TextInputValidation
          editing={true}
          name="Street address line 1: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','streetAddress')
              BusinessDetailsUpdate('street_address', streetAddress)
            }
          }
          onChange={(event) => setStreetAddress(event)}
          errors={streetAddressErr}
          value={streetAddress}
        />
        <TextInputValidation
          editing={true}
          name="Street address line 2:"
          onBlur={() => BusinessDetailsUpdate('street_address2', streetAddress2)}
          onChange={(event) => setStreetAddress2(event)}
          errors=""
          value={streetAddress2}
        />
        <TextInputValidation
          editing={true}
          name="Street address line 3:"
          onBlur={() => BusinessDetailsUpdate('street_address3', streetAddress3)}
          onChange={(event) => setStreetAddress3(event)}
          errors=""
          value={streetAddress3}
        />

        <label className={styles.column} style={{ color: 'red' }}>Use an asterisk '*' in the postal address to copy the street address</label>
        <br />
        <TextInputValidation
          editing={true}
          name="Postal address line 1: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','postalAddress')
              BusinessDetailsUpdate('postal_address', postalAddress)
            }
          }
          onChange={(event) => setPostalAddress(event)}
          errors={postalAddressErr}
          value={postalAddress}
        />

        <TextInputValidation
          editing={true}
          name="Postal address line 2:"
          onBlur={() => BusinessDetailsUpdate('postal_address2', postalAddress2)}
          onChange={(event) => setPostalAddress2(event)}
          errors=""
          value={postalAddress2}
        />
        <TextInputValidation
          editing={true}
          name="Postal address line 3:"
          onBlur={() =>  BusinessDetailsUpdate('postal_address3', postalAddress3)}
          onChange={(event) => setPostalAddress3(event)}
          errors=""
          value={postalAddress3}
        />

        <TextInputValidation
          editing={true}
          name="Telephone number of practice: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','telNr')
              BusinessDetailsUpdate('tel_nr', telNr)
            }
          }
          onChange={(event) => setTelNr(event)}
          errors={telNrErr}
          value={telNr}
        />

        <TextInputValidation
          editing={true}
          name="Cell number of practice: *"
          onBlur={
            (e:any) => {
              validation(e, 'none','cellNr')
              BusinessDetailsUpdate('cell_nr', cellNr)
            }
          }
          onChange={(event) => setCellNr(event)}
          errors={cellNrErr}
          value={cellNr}
        />

        <TextInputValidation
          editing={true}
          name="Fax number:"
          onBlur={() => BusinessDetailsUpdate('fax_nr', faxNr)}
          onChange={(event) => setFaxNr(event)}
          errors=""
          value={faxNr}
        />

        <TextInputValidation
          editing={true}
          name="Email address for practice: *"
          onBlur={
            (e:any) => {
              validation(e, 'email','debitOrderEmail')
              BusinessDetailsUpdate('email_debit_order', emailDebitOrder)
            }  
          }
          onChange={(event) => setEmailDebitOrder(event)}
          errors={emailDebitOrderErr}
          value={emailDebitOrder}
        />

        <TextInputValidation
          editing={true}
          name="Email address for newsletter: *"
          onBlur={
            (e:any) => {
              validation(e, 'email','emailNewsletter')
              BusinessDetailsUpdate('email_newsletter', emailNewsletter)
            }  
          }
          onChange={(event) => setEmailsNewsletter(event)}
          errors={emailNewsletterErr}
          value={emailNewsletter}
        />

        <TextInputValidation
          editing={true}
          name="Email address for Practice Manager: *"
          onBlur={
            (e:any) => {
              validation(e, 'email','emailPracticeManager')
              BusinessDetailsUpdate('email_practice_manager', emailPracticeManager)
            }
          }
          onChange={(event) => setEmailPracticeManager(event)}
          errors={emailPracticeManagerErr}
          value={emailPracticeManager}
        />

        <TextInputValidation
          editing={true}
          name="Email address for accounts: *"
          onBlur={
            (e:any) => {
              validation(e, 'email','emailAccounts')
              BusinessDetailsUpdate('email_accounts', emailAccounts)
            }
          }
          onChange={(event) => setEmailAccounts(event)}
          errors={emailAccountsErr}
          value={emailAccounts}
        />

        <TextInputValidation
          editing={true}
          name="Information Officers Name (POPI Act - Contracts): *"
          onBlur={
            (e:any) => {
              validation(e, 'None','creditControlName')
              BusinessDetailsUpdate('credit_control_name', creditControlName)
            }
          }
          onChange={(event) => setCreditControlName(event)}
          errors={creditControlNameErr}
          value={creditControlName}
        />

        <TextInputValidation
          editing={true}
          name="Cell number of Information Officer: *"
          onBlur={
            (e:any) => {
              validation(e, 'None','IOCellNo')
              BusinessDetailsUpdate('io_cell_nr', IOCellNo)
            }
          }
          onChange={(event) => setIOCellNo(event)}
          errors={IOCellNoErr}
          value={IOCellNo}
        />

        <TextInputValidation
          editing={true}
          name="Information Officers email address: *"
          onBlur={
            (e:any) => {
              validation(e, 'None','creditControlEmail')
              BusinessDetailsUpdate('credit_control_email', creditControlEmail)
            }
          }
          onChange={(event) => setCreditControlEmail(event)}
          errors={creditControlEmailErr}
          value={creditControlEmail}
        />

        <TextInputValidation
          editing={true}
          name="Practice (PCNS) number: *"
          onBlur={
            (e:any) => {
              validation(e, 'None','practiceNr')
              BusinessDetailsUpdate('practice_nr', practiceNr)
            }
          }
          onChange={(event) => setPracticeNr(event)}
          errors={practiceNrErr}
          value={practiceNr}
        />

        <TextInputValidation
          editing={true}
          name="HPCSA number: *"
          onBlur={
            (e:any) => {
              validation(e, 'None','hpcsaNr')
              BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
            }
          }
          onChange={(event) => setHpcsaNr(event)}
          errors={hpcsaNrErr}
          value={hpcsaNr}
        />

        <TextInputValidation
          editing={true}
          name="MHG (Metropolitan Health Group) username:"
          onBlur={() => BusinessDetailsUpdate('mhg_username', mhgUsername)}
          onChange={(event) => setMHGUsername(event)}
          errors=""
          value={mhgUsername}
        />

        <TextInputValidation
          editing={true}
          name="MHG (Metropolitan Health Group) password:"
          onBlur={() => BusinessDetailsUpdate('mhg_password', mhgPassword)}
          onChange={(event) => setMHGPassword(event)}
          errors=""
          value={mhgPassword}
        />

        <TextInputValidation
          editing={true}
          name="VAT number:"
          onBlur={() => BusinessDetailsUpdate('vat_number', vatNr)}
          onChange={(event) => setVatNr(event)}
          value={vatNr}
        />
        <InputUpload
          name="ID Document"
          accept="image/*, application/pdf"
          onBlur={() => BusinessDetailsUpdate('id_document', idDocument)}
          errors={fileLT2MBValidator(idDocument) || idDocumentErr ? [fileLT2MBValidator(idDocument), idDocumentErr].filter(Boolean) : null}
          onChange={(event: any) => setIdDocument(event)}
        />

        <InputUpload
          name="Company Document"
          accept="image/*, application/pdf"
          onBlur={() => BusinessDetailsUpdate('company_document', companyDocument)}
          errors={fileLT2MBValidator(companyDocument) || companyDocumentErr ? [fileLT2MBValidator(companyDocument), companyDocumentErr].filter(Boolean) : null}
          onChange={(event: any) => setCompanyDocument(event)}
        />

        <InputUpload
          name="Proof of Banking Details"
          accept="image/*, application/pdf"
          errors={fileLT2MBValidator(bankDetails) ? fileLT2MBValidator(bankDetails) : null}
          onChange={(event: any) => setBankDetails(event)}
        />
        </div>
        <label>Debit Order Instruction</label>
        <input type="checkbox"
              onChange={() => setDebitOrderBool(!debitorderBool)}
              checked = {!debitorderBool}
              className={styles.FieldInputBox}
              name="accountType"
        />{debitorderBoolError? 
        <><label className={styles.ErrorMsg}>{debitorderBoolError}</label><label></label></>:''}
        {!debitorderBool ?
        <>
        <div className={styles.FieldGroupSingle}>
          <TextInputValidation
          editing={true}
          name="Bank: "
          onChange={(event) => setBank(event)}
          onBlur={(e:any) => validation(e, 'None','bank')}
          errors={bankError}
          required={true}
          />
          <TextInputValidation
          editing={true}
          name="Branch: "
          onChange={(event) => setBranch(event)}
          onBlur={(e:any) => validation(e, 'None','branch')}
          errors={bankBranchError}
          required={true}
          />
          <TextInputValidation
          editing={true}
          name="Branch Code: "
          onChange={(event) => setBranchCode(event)}
          onBlur={(e:any) => validation(e, 'None','branchCode')}
          errors={branchCodeError}
          required={true}
          />
          <TextInputValidation
          editing={true}
          name="Account Name "
          onChange={(event) => setAccountName(event)}
          onBlur={(e:any) => validation(e, 'None','accountName')}
          errors={accountNameError}
          required={true}
          />
          <TextInputValidation
          editing={true}
          name="Account Number "
          onChange={(event) => setAccountNo(event)}
          onBlur={(e:any) => validation(e, 'None','accountNo')}
          errors={accountNoError}
          required={true}
          />
          <label>Account Type:</label>
              <select
              className={accountTypeError ? 'Error' : ''}
              value={accountType}
              name="accountType"
              required={true}
              
              onChange={(event) => setAccountType(event.target.value)}>
                  <option> </option>
                  <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                  <option key={2} value="Savings">Savings</option>
                  <option key={3} value="transmission">transmission</option>
          </select>
          <label>Date Of Debit Order:</label>
              <select
              className={dateOfDebitOrderError ? 'Error' : ''}
              value={dateOfDebitOrder}
              name="accountType"
              required={true}
              
              onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                  <option> </option>
                  <option key={1} value="3">3</option>
                  <option key={2} value="7">7</option>
                  <option key={3} value="15">15</option>
          </select>
          </div>
        </>
        : <></>}
        <>
        <label>Heard From: *</label>
        <Select
          className={styles.multiValue}
          isMulti={true}
          onChange={(event) => setHeardFrom(event)}
          options={[...HeardFromOptions]}
        />
        
        <label>Seen Social Media: *</label>
        <select
          className={disciplineErr ? 'Error' : ''}
          value={seenSocialMedia}
          onChange={(e) => setSeenSocialMedia(e.target.value)} 
          >
          <option></option>
          <option value='Yes'>Yes</option>
          <option value='No'>No</option>
        </select>
        <span className={styles.ErrorMsg}>{seenSocialMediaErr}</span>

        <div className={styles.TermsWrapper}>
          <h3>Acceptance of Terms of Agreement</h3>
          <p>
            I, <input className={styles.ContractUndersigned}
              name="agreement_contact"
              placeholder={"Enter Full Name *"}
              onBlur={(e:any) => validation(e, 'None','acceptContact')}
              onChange={(event) => setAcceptContact(event.target.value)}
            />, confirm that the information supplied, is correct and that I have the authority to sign on behalf of the business/practice.</p>
            <span className={styles.ErrorMsg}> {acceptContactErr}</span>
          <p>
            <input type="checkbox"
              name="agreement_checked"
              onChange={() => setAgreementCheck(true)}
              required={agreementCheck}
            /> I agree with the terms and conditions in this contract.
          </p>
        </div>
        </>
        
        {isLoading ?
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
          >
            Submit <span className="material-icons">arrow_forward</span>
          </button>
        }

        <ToastContainer />
        
        </form>
      </div>
      </DesktopLayout>
      
      <MobileLayout>
      <div className={responsive.PageWrapper}>
        <form onSubmit={validateFields}>
          <div className={responsive.OverviewPanel}>
            <div className={styles.Heading}>
              <h2>Quote: Part B</h2>
            </div>
          </div>

          <br />
        <div className={responsive.OverviewRow}>

        <TextInputValidation
          editing={true}
          name="Name of Business/Practice: *"
          onBlur={(e:any) => validation(e, 'none','businessName')}
          onChange={(event) => setBusinessName(event)}
          errors={businessNameErr}
        />
        

        <TextInputValidation
          editing={true}
          name="Trading As: *"
          onBlur={(e:any) => validation(e, 'none','tradingAs')}
          onChange={(event) => setTradingAs(event)}
          errors={tradingAsErr}
        />
        

        <label className={styles.top}>Business Type: *</label>
        <RadioGroup className={styles.column} row aria-label="position" value={businessType} onChange={(e) => setBusinessType(e.target.value)}>
          <FormControlLabel value="Sole Practitioner" control={<Radio />} label="Sole Practitioner" />
          <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
          <FormControlLabel value="Incorporation" control={<Radio />} label="Incorporation" />
          <FormControlLabel value="Close Corporation" control={<Radio />} label="Close Corporation" />
          <FormControlLabel value="(Pty) Ltd" control={<Radio />} label="(Pty) Ltd" />
          <FormControlLabel value="Trust" control={<Radio />} label="Trust" />
        </RadioGroup>

        <TextInputValidation
          editing={true}
          name="ID or Company Registration number: *"
          onBlur={(e:any) => validation(e, 'none','idRegNr')}
          onChange={(event) => setIdRegNr(event)}
          errors={idRegNrErr}
        />

        <TextInputValidation
          editing={true}
          name="Name of Contact Person: *"
          onBlur={(e:any) => validation(e, 'none','contactPerson')}
          onChange={(event) => setContactPerson(event)}
          errors={contactPersonErr}
        />

        <label>Discipline: *</label>
        <select
          className={disciplineErr ? 'Error' : ''}
          value={discipline}
          onBlur={(e:any) => validation(e, 'none','discipline')}
          onChange={(e) => setDiscipline(e.target.value)} >
          <option></option>
          {disciplineApi.map((item: any) => (
            <option key={item.name} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <div></div>
        <div></div>

        <TextInputValidation
          editing={true}
          name="Postal address line 1: *"
          onBlur={(e:any) => validation(e, 'none','postalAddres')}
          onChange={(event) => setPostalAddress(event)}
          errors={postalAddressErr}
        />

        <TextInputValidation
          editing={true}
          name="Postal address line 2:"
          onChange={(event) => setPostalAddress2(event)}
          errors=""
        />
        <TextInputValidation
          editing={true}
          name="Postal address line 3:"
          onChange={(event) => setPostalAddress3(event)}
          errors=""
        />

        <TextInputValidation
          editing={true}
          name="Street address line 1: *"
          onBlur={(e:any) => validation(e, 'none','streetAddress')}
          onChange={(event) => setStreetAddress(event)}
          errors={streetAddressErr}
        />
        <TextInputValidation
          editing={true}
          name="Street address line 2:"
          onChange={(event) => setStreetAddress2(event)}
          errors=""
        />
        <TextInputValidation
          editing={true}
          name="Street address line 3:"
          onChange={(event) => setStreetAddress3(event)}
          errors=""
        />

        <TextInputValidation
          editing={true}
          name="Telephone number of practice: *"
          onBlur={(e:any) => validation(e, 'none','telNr')}
          onChange={(event) => setTelNr(event)}
          errors={telNrErr}
        />

        <TextInputValidation
          editing={true}
          name="Cell number of practice: *"
          onBlur={(e:any) => validation(e, 'none','cellNr')}
          onChange={(event) => setCellNr(event)}
          errors={cellNrErr}
        />

        <TextInputValidation
          editing={true}
          name="Fax number:"
          onChange={(event) => setFaxNr(event)}
          errors=""
        />

        <TextInputValidation
          editing={true}
          name="Email address for practice: *"
          onBlur={(e:any) => validation(e, 'email','debitOrderEmail')}
          onChange={(event) => setEmailDebitOrder(event)}
          errors={emailDebitOrderErr}
        />

        <TextInputValidation
          editing={true}
          name="Email address for newsletter: *"
          onBlur={(e:any) => validation(e, 'email','emailNewsletter')}
          onChange={(event) => setEmailsNewsletter(event)}
          errors={emailNewsletterErr}
        />

        <TextInputValidation
          editing={true}
          name="Email address for Practice Manager: *"
          onBlur={(e:any) => validation(e, 'email','emailPracticeManager')}
          onChange={(event) => setEmailPracticeManager(event)}
          errors={emailPracticeManagerErr}
        />

        <TextInputValidation
          editing={true}
          name="Email address for accounts: *"
          onBlur={(e:any) => validation(e, 'email','emailAccounts')}
          onChange={(event) => setEmailAccounts(event)}
          errors={emailAccountsErr}
        />

        <TextInputValidation
          editing={true}
          name="Information Officers Name (POPI Act - Contracts): *"
          onBlur={(e:any) => validation(e, 'None','creditControlName')}
          onChange={(event) => setCreditControlName(event)}
          errors={creditControlNameErr}
        />

        <TextInputValidation
          editing={true}
          name="Cell number of Information Officer: *"
          onBlur={(e:any) => validation(e, 'None','IOCellNo')}
          onChange={(event) => setIOCellNo(event)}
          errors={IOCellNoErr}
        />

        <TextInputValidation
          editing={true}
          name="Information Officers email address: *"
          onBlur={(e:any) => validation(e, 'None','creditControlEmail')}
          onChange={(event) => setCreditControlEmail(event)}
          errors={creditControlEmailErr}
        />

        <TextInputValidation
          editing={true}
          name="Practice (PCNS) number: *"
          onBlur={(e:any) => validation(e, 'None','practiceNr')}
          onChange={(event) => setPracticeNr(event)}
          errors={practiceNrErr}
        />

        <TextInputValidation
          editing={true}
          name="HPCSA number: *"
          onBlur={(e:any) => validation(e, 'None','hpcsaNr')}
          onChange={(event) => setHpcsaNr(event)}
          errors={hpcsaNrErr}
        />

        <TextInputValidation
          editing={true}
          name="MHG (Metropolitan Health Group) username:"
          onChange={(event) => setMHGUsername(event)}
          errors=""
        />

        <TextInputValidation
          editing={true}
          name="MHG (Metropolitan Health Group) password:"
          onChange={(event) => setMHGPassword(event)}
          errors=""
        />

        <TextInputValidation
          editing={true}
          name="VAT number:"
          onChange={(event) => setVatNr(event)}
        />
        <InputUpload
          name="ID Document"
          accept="image/*, application/pdf"
          onBlur={() => BusinessDetailsUpdate('id_document', idDocument)}
          errors={fileLT2MBValidator(idDocument) || idDocumentErr ? [fileLT2MBValidator(idDocument), idDocumentErr].filter(Boolean) : null}
          onChange={(event: any) => setIdDocument(event)}
        />

        <InputUpload
          name="Company Document"
          accept="image/*, application/pdf"
          onBlur={() => BusinessDetailsUpdate('company_document', companyDocument)}
          errors={fileLT2MBValidator(companyDocument) || companyDocumentErr ? [fileLT2MBValidator(companyDocument), companyDocumentErr].filter(Boolean) : null}
          onChange={(event: any) => setCompanyDocument(event)}
        />

        <InputUpload
          name="Proof of Banking Details"
          accept="image/*, application/pdf"
          onBlur={() => BusinessDetailsUpdate('banking_details', bankDetails)}
          errors={fileLT2MBValidator(bankDetails) ? fileLT2MBValidator(bankDetails) : null}
          onChange={(event: any) => setBankDetails(event)}
        />
        </div>

        <label>Debit Order Instruction</label>
        <input type="checkbox"
              onChange={() => setDebitOrderBool(!debitorderBool)}
              checked = {!debitorderBool}
              className={styles.FieldInputBox}
              name="accountType"
        />{debitorderBoolError? 
        <><label className={styles.ErrorMsg}>{debitorderBoolError}</label><label></label></>:''}
        {!debitorderBool ?
        <>
        <div className={responsive.OverviewRow}>
          <TextInputValidation
            editing={true}
            name="Bank: "
            onChange={(event) => setBank(event)}
            onBlur={
              (e:any) => { 
                validation(e, 'None','bank')
                BusinessDetailsUpdate('debit_order_bank', bank)
              }
            }
            errors={bankError}
            required={true}
            value={bank}
            />
          <TextInputValidation
            editing={true}
            name="Branch: "
            onChange={(event) => setBranch(event)}
            onBlur={
              (e:any) => {
                validation(e, 'None','branch')
                BusinessDetailsUpdate('debit_order_branch', branch)
              }
            }
            errors={bankBranchError}
            required={true}
            value={branch}
          />
          <TextInputValidation
            editing={true}
            name="Branch Code: "
            onChange={(event) => setBranchCode(event)}
            onBlur={
              (e:any) => {
                validation(e, 'None','branchCode')
                BusinessDetailsUpdate('debit_order_branch_code', branchCode)
              }
            }
            errors={branchCodeError}
            required={true}
            value={branchCode}
          />
          <TextInputValidation
            editing={true}
            name="Account Name "
            onChange={(event) => setAccountName(event)}
            onBlur={
              (e:any) => {
                validation(e, 'None','accountName')
                BusinessDetailsUpdate('debit_order_account_name', accountName)
              }
            }
            errors={accountNameError}
            required={true}
            value={accountName}
          />
          <TextInputValidation
          editing={true}
          name="Account Number "
          onChange={(event) => setAccountNo(event)}
          onBlur={
            (e:any) => {
              validation(e, 'None','accountNo')
              BusinessDetailsUpdate('debit_order_account_no', accountNo)
            }
          }
          errors={accountNoError}
          required={true}
          value={accountNo}
          />
          <label>Account Type:</label>
              <select
              className={accountTypeError ? 'Error' : ''}
              value={accountType}
              name="accountType"
              required={true}
              onBlur={() => BusinessDetailsUpdate('debit_order_account_type', accountType)}
              onChange={(event) => setAccountType(event.target.value)}>
                  <option> </option>
                  <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                  <option key={2} value="Savings">Savings</option>
                  <option key={3} value="transmission">transmission</option>
          </select>
          <label>Date Of Debit Order:</label>
              <select
              className={dateOfDebitOrderError ? 'Error' : ''}
              value={dateOfDebitOrder}
              name="accountType"
              required={true}
              onBlur={() => BusinessDetailsUpdate('date_of_debit_order', dateOfDebitOrder)}
              onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                  <option> </option>
                  <option key={1} value="3">3</option>
                  <option key={2} value="7">7</option>
                  <option key={3} value="15">15</option>
          </select>
          </div>
        </>
        : <></>}
        <>
        <label>Heard From: *</label>
        <Select
          className={styles.multiValue}
          isMulti={true}
          onChange={(event) => setHeardFrom(event)}
          options={[...HeardFromOptions]}
        />
        
        <label>Seen Social Media: *</label>
        <select
          className={disciplineErr ? 'Error' : ''}
          value={seenSocialMedia}
          onChange={(e) => setSeenSocialMedia(e.target.value)} 
          >
          <option></option>
          <option value='Yes'>Yes</option>
          <option value='No'>No</option>
        </select>
        <span className={styles.ErrorMsg}>{seenSocialMediaErr}</span>

        <div className={styles.TermsWrapper}>
          <h3>Acceptance of Terms of Agreement</h3>
          <p>
            I, <input className={styles.ContractUndersigned}
              name="agreement_contact"
              placeholder={"Enter Full Name *"}
              onBlur={
                (e:any) => {
                  validation(e, 'None','acceptContact')
                  BusinessDetailsUpdate('accept_contact', acceptContact)
                }
              }
              onChange={(event) => setAcceptContact(event.target.value)}
              value={acceptContact}
            />, confirm that the information supplied, is correct and that I have the authority to sign on behalf of the business/practice.</p>
            <span className={styles.ErrorMsg}> {acceptContactErr}</span>
          <p>
            <input type="checkbox"
              name="agreement_checked"
              onChange={() => setAgreementCheck(true)}
              required={agreementCheck}
              className={responsive.InputBox}
            /> I agree with the terms and conditions in this contract.
          </p>
        </div>
        </>
        
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
          >
            Submit <span className="material-icons">arrow_forward</span>
          </button>
        }

        <ToastContainer />

        </form>
      </div>
      </MobileLayout>
      </>
      :
      <div className={styles.FormWrapper}>
        <div className={styles.QuoteCompleteCard}>
            <label className={styles.QuoteCompleteCardHeading}>Quote # {businessValues.quote_id}</label><br /><br />
            <label className={styles.QuoteCompleteCardHeading}>Your quotation has already been accepted.</label>
            <br/>
        </div>
      </div>
    }
    </>
  )
};

export default BusinessDetails;
