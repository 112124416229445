import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import inputStyles from '../Inputs.module.scss';
import Many2OneModal from './Many2OneModal';
import Many2OneType from './Many2OneTypes';

type Many2OneInputPropTypes = {
  name: string;
  value?: Many2OneType;
  link: string;
  editing: boolean;
  onChange: (value?: Many2OneType) => void;
  values?: Many2OneType[];
};

const many2OneValueToString = (value?: Many2OneType) =>
  `${value?.name} (${value?.id})`;

const Many2OneInput = ({
  name,
  value,
  link,
  editing,
  onChange,
  values,
}: Many2OneInputPropTypes) => {
  const [touched, setTouched] = useState(false);
  const [inputValue, setInputValueState] = useState(
    many2OneValueToString(value)
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!editing) {
      setTouched(false);
    }
    setInputValueState(many2OneValueToString(value));
  }, [editing, value]);

  return (
    <>
      <div className={inputStyles.FieldInput}>
        <span>{name}</span>
      </div>
      {editing ? (
        <>
          <input
            value={inputValue}
            className={inputStyles.FieldInput}
            onClick={() => setShowModal(true)}
            onFocus={() => setShowModal(true)}
            readOnly
          />
          {showModal ? (
            <Many2OneModal
              onChange={(v) => {
                setTouched(true);
                setShowModal(false);
                onChange(v);
              }}
              onClose={() => setShowModal(false)}
              values={values}
            />
          ) : null}
        </>
      ) : (
        <Link to={link}>{value?.name}</Link>
      )}
    </>
  );
};

export default Many2OneInput;
