import React from 'react';

import Validator from '../../utils/Validator';
import styles from './Inputs.module.scss'

const dispatchFileUpload = (event: any, onChange: any) => {
  const { files } = event.target;
  if (files.length > 0) {
    files[0]
      .arrayBuffer()
      .then((bytes: any) =>
        onChange(
          btoa(
            new Uint8Array(bytes).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          ),
        ),
      );
  }
};

const InputUpload = ({ name, errors, onChange, accept }: any) => (
  <>
    <div className={styles.FieldGroupSingle_File}>
      <div className={styles.FilesUpload}>
        <label className={styles.FilesBtn}>{name}
        <input
          className={errors ? 'Error' : '' && styles.FilesUpload}
          type="file"
          accept={accept}
          onChange={(e) => dispatchFileUpload(e, onChange)}
        />
        </label>
      </div>
    </div>
    <Validator {...{ errors }} />
  </>
);

export default InputUpload;
